$font-title: 'Cormorant SC', serif;

$color-font: #222;
$color-bg: #F2F2F2;
$color-link: #EC9454;
$color-link--visited: #EC9454;
$color-link--hover: #C72767;

@mixin md {
    @media (max-width: 768px) {
        @content;
    }
}