@import './common.scss';

#root {
    width: 100%;
    height: 100%;
}

#App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 500%;
        font-family: $font-title;
        font-weight: lighter;
    
        &::first-letter {
            font-size: 120%;
        }

        @include md {
            font-size: 300%;
        }
    }
    
    h2 {
        font-weight: normal;
    }

    a {
        text-decoration: none;
        color: $color-link;
        &:hover {
            color: $color-link--hover;
        }
    }

    ul {
        padding: 0;
        margin-top: 3rem;
        list-style: none;
        display: flex;

        li {
            margin: 0 0.5rem;
            color: $color-link;
        }
    }
}
